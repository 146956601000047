import React from 'react'

import { Block, Inline } from '@contentful/rich-text-types'

import { validateEmbeddedEntryNodeData } from './node-data'

import { RichTextEmbeddedEntryInlineCallToAction } from './embedded-entry-inline/call-to-action'

export function RichTextEmbeddedEntryInline({
  node,
}: {
  node: Block | Inline
  children: React.ReactNode
}): JSX.Element | null {
  const data = validateEmbeddedEntryNodeData(node.data)
  if (!data) {
    return null
  }

  switch (data.contentType) {
    case 'callToAction':
      return <RichTextEmbeddedEntryInlineCallToAction target={data.target} />
  }
}
