import React from 'react'
import { Box } from '@hub/box'
import { Stack } from '@hub/stack'

import AppleQRCode from './apple-qr-code.inline.svg'
import GoogleQRCode from './google-qr-code.inline.svg'
import { CloudinaryImage } from '../cloudinary-image'
import { Link } from '@hub/link'
import { debounce } from 'lodash'
import { useAnalytics } from '@scentregroup/shared/analytics'

export interface DownloadWestfieldAppProps {
  children: React.ReactNode
  image?: {
    src: string
    alt: string
  } | null
}

export const DownloadWestfieldApp: React.FC<DownloadWestfieldAppProps> = ({
  children,
  image,
}) => {
  const diamondOneRef = React.useRef<HTMLDivElement>(null)
  const diamondTwoRef = React.useRef<HTMLDivElement>(null)
  const [diamondOneWidth, setDiamondOneWidth] = React.useState(0)
  const [diamondTwoWidth, setDiamondTwoWidth] = React.useState(0)
  const [diamondThreeWidth, setDiamondThreeWidth] = React.useState(0)

  const { trackEvent } = useAnalytics()

  function updateSize(): void {
    const diamondOneWidth = diamondOneRef.current?.getBoundingClientRect().width
    if (diamondOneWidth) {
      setDiamondOneWidth(diamondOneWidth)
    }

    const diamondTwoWidth = diamondTwoRef.current?.getBoundingClientRect().width
    if (diamondTwoWidth) {
      const diamondThreeWidth = diamondTwoWidth - 64

      setDiamondTwoWidth(diamondTwoWidth)
      setDiamondThreeWidth(diamondThreeWidth)
    }
  }

  const debounceUpdateSize = debounce(() => {
    updateSize()
  }, 150)

  React.useEffect(() => {
    // initial render
    setTimeout(() => {
      updateSize()
    }, 0)

    window.addEventListener('resize', debounceUpdateSize)
    return () => window.removeEventListener('resize', updateSize)
  }, [debounceUpdateSize])

  const appleAppURL = 'https://apps.apple.com/au/app/westfield/id1050004889'
  const googlePlayURL =
    'https://play.google.com/store/apps/details?id=com.scentregroup.westfield'

  const triggerOnClick = (platform: 'apple' | 'android'): void => {
    trackEvent({
      object: 'App Download',
      action: 'Selected',
      properties: {
        name: platform === 'apple' ? 'Apple store' : 'Google Play store',
        link_url: platform === 'apple' ? appleAppURL : googlePlayURL,
        outbound: 'true',
        context: 'westfield membership',
      },
    })
  }

  return (
    <>
      <Stack
        direction={'row'}
        background={'surfaceBrandPrimary'}
        minHeight={'size-23'}
        shouldWrapChildren={false}
        flexDirection={['column', null, 'row']}
        paddingX={'spacing-md'}
        paddingY={'spacing-lg'}
      >
        <Box
          sx={{
            order: [2, null, 1],
            marginTop: ['spacing-3xl', null, 0],
          }}
        >
          {children}
          <Stack
            shouldWrapChildren={false}
            direction={'row'}
            gap={['spacing-3xl', 'spacing-2xl', null, 'size-11']}
            marginTop={[0, null, 'spacing-3xl']}
          >
            <Box
              sx={{
                maxWidth: 'size-14',
              }}
            >
              <Box sx={{ display: ['none', null, 'block'] }}>
                <AppleQRCode />
              </Box>
              <Link
                href={appleAppURL}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => triggerOnClick('apple')}
              >
                <CloudinaryImage
                  sx={{
                    width: 'size-14',
                    marginTop: 'spacing-2xl',
                  }}
                  imageSetOrImage={{
                    url: 'https://cam.scentregroup.io/m/beb7c689c94b4ef/original/Download-on-the-app-store.png',
                    type: 'WIDE',
                    alt: 'Download on the App Store',
                  }}
                />
              </Link>
            </Box>
            <Box
              sx={{
                maxWidth: 'size-15',
              }}
            >
              <Box
                sx={{
                  maxWidth: 'size-14',
                }}
              >
                <Box sx={{ display: ['none', null, 'block'] }}>
                  <GoogleQRCode />
                </Box>
              </Box>
              <Link
                href={googlePlayURL}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => triggerOnClick('android')}
              >
                <CloudinaryImage
                  sx={{
                    width: 'size-14',
                    marginTop: 'spacing-2xl',
                  }}
                  imageSetOrImage={{
                    url: 'https://cam.scentregroup.io/m/51fced10c6baa259/original/google-play-badge.png',
                    type: 'WIDE',
                    alt: 'Get it on Google Play',
                  }}
                />
              </Link>
            </Box>
          </Stack>
        </Box>

        <Box
          sx={{
            order: [1, null, 2],
            maxWidth: '400px',
            minWidth: ['unset', '400px'],
          }}
        >
          {/* 
              Reference divs to help with calculations with responsive diamonds... these dont show on page
              The divs wrapping the diamonds themselves aren't used as they are influenced by the diamond sizes
          */}
          <Box
            sx={{
              display: 'flex',
              minHeight: 'size-5',
            }}
          >
            <Box
              sx={{
                flexGrow: 0.95,
              }}
              ref={diamondOneRef}
            ></Box>
            <Box
              sx={{
                flexGrow: 5.05,
              }}
              ref={diamondTwoRef}
            ></Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                paddingTop: `${diamondTwoWidth / 2 - diamondOneWidth / 2}px`,
              }}
            >
              {/* diamondOne */}
              <Diamond
                width={diamondOneWidth}
                colour="shapeBrandPrimary"
              ></Diamond>
            </Box>
            <Box
              sx={{
                flexGrow: 4,
              }}
            >
              {/* diamond 2 */}
              <Diamond width={diamondTwoWidth} colour="infoLight">
                {/* diamond 3 */}
                <Diamond
                  width={diamondThreeWidth}
                  colour="shapeBrandAccent02Dark"
                  zIndex={2}
                >
                  {image ? (
                    <CloudinaryImage
                      sx={{
                        position: 'absolute',
                        zIndex: 3,
                        width: 'calc(100% - 67px)',
                        height: 'auto',
                        clipPath: 'polygon(50% 0, 100% 50%, 50% 100%, 0 50%)',
                      }}
                      ratio="1"
                      imageSetOrImage={{
                        url: image.src,
                        type: 'SQUARE',
                        alt: image.alt,
                      }}
                      resizeMode="crop-center"
                      sizes={['size-16', null, 'size-22']}
                    />
                  ) : null}
                </Diamond>
              </Diamond>
            </Box>
          </Box>
        </Box>
      </Stack>
    </>
  )
}

interface DiamondProps {
  width: number
  colour: string
  zIndex?: number
  children?: React.ReactNode
}

const Diamond: React.FC<DiamondProps> = ({
  width,
  colour,
  zIndex,
  children,
}) => {
  return (
    <Box
      sx={{
        position: 'relative',
        width: `${width}px`,
        height: `${width}px`,
        display: 'flex',
        flexShrink: 0,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        _after: {
          zIndex: zIndex,
          content: '""',
          display: 'block',
          background: colour,
          position: 'absolute',
          boxShadow: '0px 0px 20px 0px rgba(176, 176, 176, 0.2)',
          top: '15%',
          bottom: '15%',
          left: '15%',
          right: '15%',
          transform: 'rotate(45deg)',
        },
      }}
    >
      {children}
    </Box>
  )
}
