import React from 'react'

import { Link } from '@hub/link'

import { StandardButton } from '../../../standard-button'
import { RichTextEmbeddedEntryTargetCallToAction } from '../node-data'

export function RichTextEmbeddedEntryInlineCallToAction({
  target: {
    fields: { address, category },
  },
}: {
  target: RichTextEmbeddedEntryTargetCallToAction
}): JSX.Element | null {
  return (
    <StandardButton as={Link} variant="solid" href={address}>
      {category}
    </StandardButton>
  )
}
