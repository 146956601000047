import React from 'react'
import classNames from 'classnames'
import { get } from 'lodash'
import { Stack } from '@hub/stack'
import { withIsAboveFoldProvider } from '@scentregroup/shared/hub-components/is-above-fold-provider'
import { SGPageTemplate } from '@scentregroup/shared/types/page-templates'

import { Module } from '../../../../apps/website/components/page-templates/modules'

export interface ITemplateProps {
  template: SGPageTemplate
  id?: string
  activateH1Title?: boolean
  children?: React.ReactElement | React.ReactElement[]
}

const ABOVE_FOLD_THRESHOLD = 2

// Is there a better way to do this?
const Div: React.FC<
  React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>
> = props => <div {...props} />

export function getTemplateModules(
  template: SGPageTemplate,
  activateH1Title = true
): JSX.Element[] {
  let firstChildProcessed = false
  return template.modules.flatMap((module, idx) => {
    let enableH1Heading = false
    const args: string[] = ['sc-slot', `sc-slot-${idx}`]
    if (Object.keys(module).includes('analyticsMetadata')) {
      args.push(
        `ad-content-${
          (module as any).analyticsMetadata.displayId.split('/')[3]
        }`
      )
    }

    if (
      activateH1Title &&
      !firstChildProcessed &&
      get(module, 'title') &&
      (get(module, 'items') || []).length > 0
    ) {
      firstChildProcessed = enableH1Heading = true
    }

    const DivWithFold = withIsAboveFoldProvider(
      Div,
      idx < ABOVE_FOLD_THRESHOLD ? 'above' : 'below'
    )
    return (
      <DivWithFold key={idx} className={classNames(...args)}>
        <Module module={module} enableH1Heading={enableH1Heading} />
      </DivWithFold>
    )
  })
}

export function Template({
  template,
  id,
  activateH1Title,
  children,
}: ITemplateProps): JSX.Element {
  const modules = React.useMemo(() => {
    return getTemplateModules(template, activateH1Title)
  }, [template, activateH1Title])
  return (
    <>
      {modules.length ? (
        <Stack gap={['spacing-3xl', null, 'spacing-4xl']} id={id}>
          {children}
          {modules}
        </Stack>
      ) : null}
    </>
  )
}
