import React from 'react'

import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS, Document, INLINES } from '@contentful/rich-text-types'
import { Text } from '@hub/text'
import { H1, H2, H3 } from '@hub/heading'
import { OrderedList, UnorderedList } from '@hub/list'
import { Link } from '@hub/link'

import { RichTextEmbeddedEntryInline } from './embedded-entry/embedded-entry-inline'

export interface RichTextProps {
  body?: Document | null
  options?: Options
}

export const RichText: React.FC<RichTextProps> = ({ body, options = {} }) => {
  if (!body) {
    return null
  }

  const { renderMark, renderNode, ...rest } = options

  return (
    <>
      {documentToReactComponents(body, {
        renderMark: { ...defaultOptions.renderMark, ...renderMark },
        renderNode: { ...defaultOptions.renderNode, ...renderNode },
        ...rest,
      })}
    </>
  )
}

const headerMargin = {
  marginTop: ['spacing-lg', 'spacing-2xl'],
  '&:first-child': { marginTop: 0 },
}

const defaultOptions: Options = {
  renderMark: {
    [MARKS.BOLD]: text => (
      <Text as={'strong'} sx={{ fontWeight: 'medium' }}>
        {text}
      </Text>
    ),
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      // Remove empty paragraphs placed in by rich text editor
      if (
        Array.isArray(children) &&
        (children.length === 0 || (children.length === 1 && children[0] === ''))
      ) {
        return null
      }
      return <Text sx={{ marginTop: 'spacing-lg' }}>{children}</Text>
    },
    [BLOCKS.HEADING_1]: (node, children) => (
      <H1 sx={{ ...headerMargin }}>{children}</H1>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <H2 sx={{ ...headerMargin }}>{children}</H2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <H3 sx={{ ...headerMargin }}>{children}</H3>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <UnorderedList>{children}</UnorderedList>
    ),
    [BLOCKS.OL_LIST]: (node, children) => <OrderedList>{children}</OrderedList>,
    [INLINES.EMBEDDED_ENTRY]: (node, children) => (
      <RichTextEmbeddedEntryInline node={node}>
        {children}
      </RichTextEmbeddedEntryInline>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <Link hasUnderline href={node.data.uri}>
        {children}
      </Link>
    ),
  },
}

export default RichText
