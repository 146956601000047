import { NodeData } from '@contentful/rich-text-types'

export type RichTextEmbeddedEntryData = {
  [key in keyof RichTextEmbeddedEntryTargetByContentType]: {
    contentType: key
    target: RichTextEmbeddedEntryTargetByContentType[key]
  }
}[keyof RichTextEmbeddedEntryTargetByContentType]

type RichTextEmbeddedEntryTargetByContentType = {
  callToAction: RichTextEmbeddedEntryTargetCallToAction
}

type RichTextEmbeddedEntryTargetEntry<
  ContentType extends string,
  Fields extends Record<string, unknown> = Record<string, unknown>,
> = {
  sys: {
    id: string
    contentType: {
      sys: {
        id: ContentType
      }
    }
  }
  fields: Fields
}

export type RichTextEmbeddedEntryTargetCallToAction =
  RichTextEmbeddedEntryTargetEntry<
    'callToAction',
    {
      category: string
      address: string
    }
  >

export function validateEmbeddedEntryNodeData(
  data: NodeData | null | undefined
): RichTextEmbeddedEntryData | null {
  if (!data?.target) {
    return null
  }

  const contentType = extractEmbeddedEntryTargetContentType(data.target)
  if (!contentType) {
    return null
  }

  switch (contentType) {
    case 'callToAction': {
      const target = validateEmbeddedEntryTargetCallToAction(data.target)
      return target && { contentType, target }
    }
    default:
      return null
  }
}

function extractEmbeddedEntryTargetContentType(target: any): string | null {
  return target?.sys?.contentType?.sys?.id || null
}

function validateEmbeddedEntryTargetCallToAction(
  target: any & { sys: { contentType: { sys: { id: 'callToAction' } } } }
): RichTextEmbeddedEntryTargetCallToAction | null {
  const { category, address } = target.fields || {}

  if (!validateStringField(category) || !validateStringField(address)) {
    return null
  }

  return { sys: target.sys, fields: { category, address } }
}

function validateStringField(value: any): value is string {
  if (!value || typeof value !== 'string') {
    return false
  }

  return true
}
